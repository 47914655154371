@import './theme.scss';

/* stylelint-disable */
:export {
  mobile: #{$media-mobile};
  nomobile: #{$media-non-mobile};
  tabletlandscape: #{$media-tablet-landscape};
  tabletportrait: #{$media-tablet-portrait};
  desktop: #{$media-desktop};
  tablet: #{$media-tablet};
}
/* stylelint-enable */
