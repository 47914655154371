@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'almoni-bold';
  src: url('./fonts/AlmoniNeueTzarAAA-Bold.otf') format('opentype');
  font-weight: normal; // 700
  font-style: normal;
}
// src: url('./fonts/almoni-dl-aaa-bold-webfont.woff') format('woff');
// src: url('./fonts/almoni-dl-aaa-bold-webfont.woff2') format('woff2');

@font-face {
  font-family: 'almoni-demibold';
  src: url('./fonts/almoni-tzar-demibold-aaa.eot') format('embedded-opentype');
  src: url('./fonts/almoni-tzar-demibold-aaa.woff') format('woff');
  src: url('./fonts/almoni-tzar-demibold-aaa.woff2') format('woff2');
  font-weight: normal; // 600
  font-style: normal;
}

@font-face {
  font-family: 'almoni-regular';
  src: url('./fonts/almoni-tzar-aaa-regular-webfont.woff') format('woff');
  src: url('./fonts/almoni-tzar-aaa-regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'almoni-light';
  src: url('./fonts/AlmoniNeueTzarAAA-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
