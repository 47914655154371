@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.instagram-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #09182f;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  .close-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 18px;
    height: 18px;
    display: block;

    &:hover {
      span {
        opacity: 0.8;
      }
    }

    span {
      position: absolute;
      width: 20px;
      height: 2px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: white;

      &:first-child {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .top-text {
    display: block;
    position: absolute;
    top: 11px;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    font-family: $almoni-regular;
    color: #fff;
    font-size: 24px;
    line-height: 25px;
    letter-spacing: 0;
    white-space: nowrap;
  }

  .content {
    width: 255px;

    .logo-wrapper {
      width: 150px;
      margin: 0 auto 14px;
      img {
        display: block;
        width: 100%;
      }
    }

    .photo {
      width: 212px;
      height: 212px;
      border-radius: 3px;
      margin: 0 auto 16px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 25%;
      }
    }

    .title {
      font-family: $almoni-bold;
      color: #fff;
      font-size: 32px;
      line-height: 25px;
      letter-spacing: 0.5px;
      padding-bottom: 3px;
      text-align: center;
    }

    .subtitle {
      font-family: 'Open Sans', sans-serif;
      color: #fff;
      opacity: 0.7;
      font-size: 16px;
      line-height: 21px;
      padding-bottom: 15px;
      text-align: center;
      max-width: 310px;
    }

    .count-info {
      font-family: $almoni-regular;
      color: #fff;
      font-size: 24px;
      line-height: 25px;
      letter-spacing: 0.5px;
      padding-bottom: 16px;
      text-align: center;
    }

    .mako-logo {
      width: 66px;
      margin: 0 auto;
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
