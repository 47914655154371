@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.person-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(20, 44, 81, 0.9);
    opacity: 0.9;
    z-index: 1;
  }

  .modal-content {
    width: 562px;
    min-height: 300px;
    position: relative;
    z-index: 2;
    background-color: #09182f;
    border-radius: 6px;
    padding: 42px 110px 39px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include screen('mobile') {
      position: relative;
      width: 80%;
      padding: 40px 10px 102px;
    }

    .photo {
      width: 194px;
      height: 194px;
      border-radius: 3px;
      margin-bottom: 16px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 25%;
      }
    }

    .title {
      font-family: $almoni-bold;
      color: #fff;
      font-size: 32px;
      line-height: 25px;
      letter-spacing: 0.5px;
      padding-bottom: 3px;
      text-align: center;
    }

    .subtitle {
      font-family: 'Open Sans', sans-serif;
      color: #fff;
      opacity: 0.7;
      font-size: 16px;
      line-height: 21px;
      padding-bottom: 32px;
      text-align: center;
      max-width: 310px;
    }

    .count-info {
      font-family: $almoni-regular;
      color: #fff;
      font-size: 24px;
      line-height: 25px;
      letter-spacing: 0.5px;
      padding-bottom: 32px;
      text-align: center;
    }

    .close-btn {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 18px;
      height: 18px;
      display: block;

      &:hover {
        span {
          opacity: 0.8;
        }
      }

      span {
        position: absolute;
        width: 20px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: white;

        &:first-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    .instagram-popup-btn {
      display: none;
      @include screen('mobile') {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 72px;
        font-family: $almoni-regular;
        cursor: pointer;
        background-color: rgba(23, 130, 228, 0.1);

        .image-wrapper {
          width: 30px;
          margin-left: 15px;
          img {
            display: block;
            width: 100%;
          }
        }

        .text {
          p {
            margin: 0;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0;
            font-family: $almoni-regular;
            color: rgba(235, 199, 20, 1);
            &:first-child {
              font-size: 24px;
              line-height: 26px;
              font-family: $almoni-bold;
            }
          }
        }
      }
    }
  }
}
