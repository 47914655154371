@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.main-page {
  text-align: center;
}

.page-wrapper {
  margin: 0 auto;
  width: 77vw;
  padding-top: 115px;
  padding-bottom: 40px;

  @include screen('tablet') {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include screen('mobile') {
    width: 100%;
    padding-top: 74px;
    padding-bottom: 15px;
  }
}

.fixedBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 23px 11px;
  z-index: 1;
  transition: 0.3s;
  @include screen('mobile') {
    padding: 15px 20px 9px 20px;
  }

  &.sticky {
    background-color: #09182f;

    .title,
    .mako-link {
      opacity: 1;
      visibility: visible;
    }
  }

  .title {
    font-family: $almoni-bold;
    font-size: 77px;
    line-height: 0.95;
    color: #f0f0f0;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    cursor: pointer;
    padding-right: 78px;

    span {
      color: #ebc714;
    }

    @include screen('mobile') {
      font-size: 32px;
      padding-top: 5px;
      padding-right: 0;
      padding-left: 50px;
    }
  }

  .mako-link {
    width: 120px;
    opacity: 0;
    visibility: hidden;
    @include screen('mobile') {
      width: 88px;
      padding-top: 12px;
    }
    img {
      width: 100%;
    }
  }
}

.top-section {
  position: relative;
  max-width: 990px;
  margin: 0 auto;
  text-align: center;

  .mako-link {
    display: block;
    width: 129px;
    margin: 0 auto 10px;
    transform: translateX(-20px);

    img {
      width: 100%;
    }

    @include screen('mobile') {
      margin: 0 auto;
    }
  }

  .title {
    font-family: $almoni-bold;
    font-size: 140px;
    line-height: 0.95;
    color: #f0f0f0;
    margin: 0;

    width: 524px;
    margin: 0 auto;

    img {
      width: 100%;
    }

    @include screen('mobile') {
      width: 322px;
      font-size: 28px;
    }

    // @include screen('mobile') {
    //   font-size: 84px;
    //   line-height: 0.77;
    // }
  }

  .text {
    font-family: 'Open Sans';
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.2px;
    font-weight: 400;
    margin: -13px 0 18px;
    @include screen('mobile') {
      font-size: 20px;
      line-height: 25px;
      margin: 6px 12px 23px;
    }

    span {
      font-weight: 700;
    }
  }
}

.search-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 575px;
  padding: 36px 0 40px;
  @include screen('mobile') {
    padding: 12px 12px 35px;
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    width: 575px;
    height: 51px;
    border: 1px solid rgba(4, 71, 109, 0.5);

    border-radius: 6px;
    overflow: hidden;

    &.isFocused {
      border: solid 1px #1782e4;
    }

    @include screen('mobile') {
      flex-grow: 1;
    }
  }

  .input-wrapper {
    position: relative;
    flex-grow: 1;

    input {
      display: block;
      width: 100%;
      height: 49px;
      line-height: 40px;
      padding: 0 12px 0 31px;
      outline: none;
      border: none;
      font-size: 16px;
      font-family: 'Open Sans';
      font-weight: 400;
      color: #000;
      border-radius: 0;
      background-color: #fff;

      &::placeholder {
        color: #7d8d96;
      }
    }
    input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    /* clears the ‘X’ from Chrome */
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    .clear-btn {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 18px;
        height: 1px;
        top: 50%;
        left: 50%;
        background-color: #7d8d96;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .search-btn {
    position: relative;
    width: 48px;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    background-color: #fff;
    .search-icon {
      path {
        fill: #1782e4;
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 39px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: #1782e4;
    }
    &:hover {
      background-color: #1782e4;
      .search-icon {
        path {
          fill: white;
        }
      }
    }
    &.disabled {
      cursor: inherit;
      pointer-events: none;
    }

    .search-icon {
      transition: 0.3s;
    }
  }

  .advanced-search-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 51px;
    border: solid 1px transparent;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    cursor: pointer;

    @include screen('mobile') {
      flex-shrink: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 11.5px;
      top: 14px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: rgba(222, 0, 0, 1);
      opacity: 0;
    }

    &.active {
      border: solid 1px rgba(222, 0, 0, 1);
      &:after {
        opacity: 1;
      }
    }

    .img {
      display: block;
      width: 18px;
    }
  }
}

.status-container {
  position: relative;
  display: flex;
  gap: 1px;
  height: 56px;
  margin: 30px 0 22px;

  @include screen('mobile') {
    height: 41px;
    margin: 17px 12px 15px;
  }

  label {
    @include screen('mobile') {
      width: 25%;
    }

    span {
      cursor: pointer;

      height: 100%;
      min-width: 120px;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #2c4162;
      color: white;
      font-family: 'Open Sans';
      font-size: 20px;
      line-height: 1;
      transition: background-color 0.3s;

      &:hover {
        background-color: rgba(#2c4162, 0.75);
      }

      @include screen('mobile') {
        min-width: auto;
        font-size: 15px;
        padding: 0 12px;
      }
    }

    input {
      display: none;

      &:checked {
        & + span {
          cursor: default;

          background-color: #1782e4;
          box-shadow: 0px 0px 0px 1px #1782e4;
        }
      }
    }

    &:first-child {
      span {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    &:last-child {
      span {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
}

.sorting {
  position: relative;
  padding-bottom: 25px;
  @include screen('mobile') {
    padding: 0px 13px 13px;
  }

  .sort-btn {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    padding: 12px 20px;
    border: 1px solid #fff;
    background-color: #2b2c2d;
    padding: 12px 11px 12px 13px;
    transition: 0.3s;

    &:hover {
      background-color: #de000033;
      border: 1px solid #fff;
    }

    &.active {
      background-color: #de0000;
      border: 1px solid #de0000;
    }

    &.AZSorting {
      border-right: 0;
      border-radius: 2px 0px 0px 2px;
    }
    &.reverseSorting {
      border-left: 0;
      border-radius: 0px 2px 2px 0px;
    }
  }
}

.items-grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: min-content 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 25px;
  padding-bottom: 27px;
  @include screen('mobile') {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    padding: 0 12px 12px;
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .prev-btn,
  .next-btn {
    font-family: 'Open Sans';
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;

    &.disabled {
      display: none;
    }
  }

  .prev-btn {
    position: relative;
  }

  .divider {
    width: 1px;
    height: 27px;
    margin: 0 20px;
    background-color: #d9d9d9;
    &.disabled {
      display: none;
    }
  }
}

.contact {
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  padding-top: 15px;
  padding-left: 5px;
  padding-bottom: 20px;
  text-align: center;
  @include screen('mobile') {
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    padding: 14px 8px 20px;
  }
  &:hover {
    opacity: 0.8;
  }

  .whatsapp-link {
    display: flex;
    align-items: center;
    justify-content: center;
    @include screen('mobile') {
      max-width: 282px;
      margin: 0 auto;
    }

    img {
      width: 28px;
      height: 28px;
      margin-left: 10px;
      @include screen('mobile') {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.no-search-results {
  position: relative;
  padding: 32px 0;
  font-size: 40px;
  line-height: 20px;
  color: #fff;
  font-family: $almoni-demibold;
  text-align: center;
}

footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;

  @include screen('mobile') {
    flex-direction: column;
    align-items: center;
  }

  .credits {
    color: #ffffffb3;
    font-family: $almoni-regular;
    text-align: left;
    margin-right: 20px;

    @include screen('mobile') {
      text-align: center;
      margin-right: 0;
      margin-top: 10px;
    }
    .rabbiLink {
      text-decoration: underline;
      transition: 0.3s;

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .accessibility-link {
    display: inline-block;
    font-family: 'Open Sans';
    font-weight: 700;
    line-height: 1.2;
    color: #ffffffb3;
    text-decoration: underline;
    transition: 0.3s;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

@keyframes skeletonShine {
  to {
    background-position: 0 0;
  }
}
